* {
  margin: 0;
  padding: 0;
  // transition: 0.3s ease-in-out;
  letter-spacing: 0.3px;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
