@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");

:root {
  --color-primary: white;
  --color-font: white;
  // --color-background: rgba(0, 0, 0, 1);
  --background-mobile-nav: linear-gradient(
    180deg,
    rgba(96, 128, 176, 1) 0%,
    rgba(158, 102, 97, 1) 40%,
    rgba(158, 102, 97, 1) 60%,
    rgba(35, 49, 73, 1) 100%
  );

  --color-overlay: rgba(0, 0, 0, 0.9);
  --color-overlay-30: rgba(0, 0, 0, 0.3);
  --color-overlay-50: rgba(0, 0, 0, 0.5);
  --color-disabled: rgba(255, 255, 255, 0.3);

  --gradient-primary-90: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    var(--color-primary) 100%
  );
  --gradient-primary-180: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    var(--color-primary) 100%
  );
  --gradient-primary-270: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    var(--color-primary) 100%
  );

  --default-border: 1px solid var(--color-font);
}

[data-theme="white"] {
  --color-primary: black;
  --color-font: black;
  --color-active-font: black;
  --color-border: #666;

  --background-mobile-nav: linear-gradient(
    180deg,
    rgba(220, 220, 220, 1) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(220, 220, 220, 1) 100%
  );
  --color-overlay: rgba(239, 239, 239, 0.9);
  --color-overlay-30: rgba(239, 239, 239, 0.3);
  --color-overlay-50: rgba(239, 239, 239, 0.5);
  --color-disabled: white;

  --gradient-primary-90: linear-gradient(
    90deg,
    var(--color-disabled) 0%,
    var(--color-primary) 100%
  );
  --gradient-primary-180: linear-gradient(
    180deg,
    var(--color-disabled) 0%,
    var(--color-primary) 100%
  );
  --gradient-primary-270: linear-gradient(
    270deg,
    var(--color-disabled) 0%,
    var(--color-primary) 100%
  );

  --default-border: 1px solid var(--color-border);
}

[data-subtheme="dark"] {
  --color-primary: white;
}
[data-subtheme="light-blue"] {
  --color-primary: #73d4ff;
}
[data-subtheme="red"] {
  --color-primary: #ff4040;
}
[data-subtheme="green"] {
  --color-primary: #0ad100;
}

// $color--font: black;
// $color--font: white;
// $color--disabled: rgba(255, 255, 255, 0.5);
// $color--disabled-border: rgba(255, 255, 255, 0.3);
// $color--primary: rgba(220, 50, 50, 1);
// $color--primary: white;
// $color--primary--hover: #ff4040;
// $color--background: rgba(239, 239, 239, 1);
// $color--background: rgb(20, 20, 20);
// $color--overlay: rgba(239, 239, 239, 0.3);
// $color--overlay: rgba(0, 0, 0, 0.3);

$default--font: "Raleway", sans-serif;
$default--transition: 0.3s ease-in-out;

$font-size--s: 1rem;
$font-size--m: 1.2rem;
$font-size--d: 1.4rem;
$font-size--ml: 1.6rem;
$font-size--l: 2rem;

// $gradient--primary--90: linear-gradient(
//   90deg,
//   rgba(255, 255, 255, 1) 0%,
//   $color--primary 100%
// );
// $gradient--primary--180: linear-gradient(
//   180deg,
//   rgba(255, 255, 255, 1) 0%,
//   $color--primary 100%
// );
// $gradient--primary--270: linear-gradient(
//   270deg,
//   rgba(255, 255, 255, 1) 0%,
//   $color--primary 100%
// );

@import "base";
@import "header";
@import "about";
@import "contact";
@import "landing";
@import "landingArt";
@import "works";
@import "loader";
@import "notfound";
@import "animation";
@import "mobile";
