.landing-art__container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  // transition: 0.3s ease-in-out;
}
.landing-art__overlay-intro {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  background-color: black;

  z-index: 4;
}
.landing-art__overlay {
  position: absolute;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  background-color: black;
  opacity: 0;

  transition: opacity 1s ease-in-out;

  z-index: 5;
}
.cursor {
  width: 100vh;
  height: 100vh;

  position: fixed;
  top: -50vh;
  left: -50vh;
  // z-index: 10;
  contain: layout style size;

  // background-color: rgb(0, 100, 0);
  border-radius: 100%;

  pointer-events: none;
  mix-blend-mode: difference;
}
.lname {
  position: absolute;
  // max-width: 200vw;
  // max-height: 100vh;
  // z-index: -1;
  // overflow: hidden;
}

.lname-container {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  // transition: filter 0.5s ease-in-out;
}
.lname--center {
  width: 30vh;
  height: 30vh;
  border: 5px solid white;
  // background-color: rgba(0, 0, 0, 0.3);
  // mix-blend-mode: overlay;
}
.lname--first {
  width: 80vw;
  mix-blend-mode: difference;
  left: 0;
}

.lname--last {
  width: 70vw;
  mix-blend-mode: difference;
  right: 0;
}
