html {
  height: -webkit-fill-available;
  background-color: black;
}

body {
  color: var(--color-font);
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: $font-size--d;
  // font-weight: 300;
  line-height: 1;

  // width: 50vw;
  // max-height: 100vh;

  // background-image: url(../assests/image/bg-pattern.png);
  // background-size: 100% 100%;
  background-repeat: repeat;
  background-position: center center;
  background-blend-mode: difference;

  // overflow-y: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

a {
  color: var(--color-font);
  text-decoration: none;
}
.wrapper__main {
  position: relative;
  width: 100%;
  // background-color: red;

  height: 100vh;
  height: var(--app-height, 100vh);

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  // scroll-snap-type: y mandatory;
  overflow-y: hidden;
  overflow-x: hidden;
}
.active--text {
  color: var(--color-active-font);
  opacity: 1 !important;
}
.button {
  width: 100%;
  border: var(--default-border);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    // background-color: red;
  }
}

.wrapper__header {
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
}
.transition-overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: linear-gradient(
  //   60deg,
  //   rgba(96, 128, 176, 0.7) 0%,
  //   rgba(158, 102, 97, 0.7) 40%,
  //   rgba(35, 49, 73, 0.7) 100%
  // );

  background-color: rgba(200, 200, 200, 1);

  // transition: opacity 0.3s;

  // transform: translateY(100vh);

  // z-index: 9;
}
.middle-line {
  display: grid;
  // grid-template-columns: auto 1fr;
  justify-content: center;
  & .round {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & span {
      min-width: 1.2rem;
      min-height: 1.2rem;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: var(--default-border);
      box-sizing: border-box;
    }
  }
  & .middle-border {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    & span {
      width: 100%;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}
.loading__container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  // background-color: red;
  // background: red;

  z-index: 11;

  // transition: opacity 1s, transform 0.5s ease-in-out;
  & .loading__animated-div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    // transition: 1s ease-in-out;
    // background-color: black;

    & span {
      color: var(--color-font);
      font-size: 3rem;
      mix-blend-mode: difference;
    }
  }
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;
  // background-color: rgba(66, 55, 55, 0.95);
  background-blend-mode: normal;

  opacity: 1;

  z-index: 0;

  transition: $default--transition;
  &.default {
    background-color: var(--color-background);
  }
  &.about {
    background-color: yellow;
  }
}

.detail-item {
  text-transform: uppercase;
  & h3 {
    font-weight: 400;
    // color: var(--color-primary);
  }
  & p {
    font-weight: 600;
  }
  & .text {
    & p {
      font-size: $font-size--ml;
      font-weight: 300;
      text-transform: none;
    }
  }
}
.detail-item__title {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 1rem;

  padding: 1rem 0;
  margin-bottom: 1rem;

  box-sizing: border-box;

  & div:last-child {
    height: 1px;
    background: var(--gradient-primary-270);
  }
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--row {
  display: flex;
  flex-direction: row;
}

.grid--column--2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-auto-columns: 1fr;
}

.grid--column--auto {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}
.grid--row--2 {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.grid--column--span2 {
  grid-column: 1 / span 2;
}

.social-icons {
  cursor: pointer;
}

.cursor--follow {
  position: fixed;
  margin-top: 2rem;
  margin-left: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 0.5rem;
  // justify-content: center;
  // width: 100px;
  // height: 100px;
  // background-color: red;
  // transform: translateX(0) translateY(0);
  transition: opacity 0.3s ease-in-out 0s, transform 0.3s linear;

  z-index: 10;
}
.cursor__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.cursor__text {
  color: var(--color-primary);
  font-size: $font-size--s;
  font-weight: 800;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out;
}

.cursor__icon {
  position: relative;
  height: 5rem;
  display: flex;
  & svg {
    position: absolute;
    height: 100%;

    & path,
    rect,
    circle,
    polygon {
      fill: var(--color-primary);
      transition: fill 0.5s ease-in-out;
    }
  }
}
.cursor__icon.scroll {
  aspect-ratio: 1/2;
  & .svg__scroll-bar {
    width: 100%;
    height: 100%;
    // border: 1px solid white;
    // border-radius: 5rem;
    // box-sizing: border-box;
  }
  & .svg__scroll-wheel {
    top: 10%;
    left: 20%;
    height: 45%;
    animation-name: scroll;
    animation-duration: 3s;
    // animation-delay: 2s;
    animation-iteration-count: infinite;
  }
}

.cursor__icon.move {
  aspect-ratio: 1/1;

  & .svg__move-border {
    width: 100%;
    height: 100%;
  }

  & .svg__move-ball {
    top: 0%;
    left: 0%;
    height: 50%;
    animation-name: move;
    animation-duration: 4s;
    // animation-delay: 2s;
    animation-iteration-count: infinite;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(80%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes move {
  0% {
    transform: translateY(75%) translateX(65%);
  }
  25% {
    transform: translateY(0%) translateX(10%);
  }
  50% {
    transform: translateY(75%) translateX(65%);
  }
  75% {
    transform: translateY(0%) translateX(115%);
  }
  100% {
    transform: translateY(75%) translateX(65%);
  }
  // 50% {
  //   transform: translateY(80%) translateX(80%);
  // }
  // 100% {
  //   transform: translateY(0px) translateX(0px);
  // }
}
