.m--nav__toggle {
  display: none;
}
.m--nav__overlay {
  display: flex;
}
.header__container {
  position: fixed;
  height: 100%;

  display: grid;
  grid-template-rows: auto auto;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  gap: 2vh;

  padding-left: 3vw;
  // padding-right: 3vw;

  z-index: 10;
  // background-color: red;
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 0, 0, 0.9) 0%,
  //   rgba(0, 0, 0, 0.7) 50%,
  //   rgba(0, 0, 0, 0) 100%
  // );
}

//
.nav__container {
  width: auto;
  height: auto;
  display: flex;
  gap: 1rem;

  transition-property: height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.nav__container--ada {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;

  & ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    margin-left: 2vw;

    & li {
      list-style: none;
    }
  }

  & a {
    font-size: 10rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1rem;

    &:focus {
      color: red;
    }
  }
}

.nav {
  font-size: $font-size--d;

  position: relative;

  width: auto;
  height: 100%;

  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-rows: 10rem auto 10rem 1.6rem;

  transition-property: grid-template-rows;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.5s;

  & a,
  span {
    // color: $color--disabled;
    text-transform: uppercase;
  }
  & .works {
    min-height: 10rem;
  }
}
.nav__link {
  min-height: 10rem;
  position: relative;
}
.nav__sublinks {
  // position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  // gap: 2rem;
  padding: 1rem 0rem 1rem 1rem;
  opacity: 1;
  box-sizing: border-box;
  // transition-property: max-height;
  // transition-duration: 0.3s;
  // transition-timing-function: ease-in-out;
  // transition-delay: 1s;
  transition: $default--transition;
  overflow: hidden;
  & li {
    list-style: none;
    white-space: nowrap;
    // text-transform: uppercase;
    // display: flex;
    cursor: pointer;
    & a,
    span {
      display: block;
      padding: 1rem;

      opacity: 0.5;
      transition: $default--transition;
      box-sizing: border-box;
    }

    &:hover {
      & a {
        opacity: 1;
      }
    }
  }
}

.header__container.social-icons {
  width: 2.5rem;
  height: 2.5rem;
}

.icons__container {
  // position: absolute;
  // bottom: 5vw;
  width: 3rem;

  // left: calc(5vw + 0rem);

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.scroll-status__container {
  position: relative;
  min-width: 20px;
  // background-color: red;
  display: flex;
  justify-content: center;
  top: 0rem;
  height: calc(100% - 0rem);
}

.scroll-status {
  position: absolute;
  width: 1px;

  left: 9px;
  top: 0;
  border-width: 0 0 0 0px;
  border-style: solid;
  box-sizing: border-box;
  // background: linear-gradient(
  //   184deg,
  //   rgba(255, 255, 255, 0.1) 0%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // transition: background 1s ease-in-out, height 0.3s ease-in-out;
  transition: $default--transition;
}

.scroll-status--disabled {
  @extend .scroll-status;
  height: calc(100% - 0px);
  background: var(--color-disabled);
  mix-blend-mode: overlay;
  // transition: background 1s ease-in-out;
}

.scroll-status--active {
  @extend .scroll-status;
  height: 100%;
  // border-color: white;
  background: var(--gradient-primary-180);
  overflow: hidden;
  // transition: width 0.5s ease-in-out, background 1s ease-in-out;
  // transition: $default--transition;
  // transition: background 1s ease-in-out;
}
.scroll-status--boxes {
  position: absolute;
  top: 0;
  // transform: translateY(33%);
  left: 4.75px;
  // height: calc(100% - 1rem);
  display: grid;
  // grid-template-rows: 1fr 1fr 1fr auto;
  transition: transform 0.3s ease-in-out;

  & span {
    width: 1rem;
    height: 1rem;
    // border: 1px solid $color--disabled;
    box-sizing: border-box;
    background-color: var(--color-primary);
    transition: background-color 0.3s ease-in-out;
  }
}
