.landing__container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  // background-color: red;
}
.landing__info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__footer {
  color: white;
  font-size: $font-size--d;

  position: absolute;
  bottom: 7vh;
  right: 5vw;

  display: grid;
  grid-template-columns: 1.2fr 1fr;
  column-gap: 2rem;

  // mix-blend-mode: overlay;
  transition: $default--transition;

  // &:hover {
  //   animation-duration: 3s;
  //   animation-name: gradient;
  //   animation-iteration-count: infinite;
  // }
  z-index: 10;
}

.landing__center-info {
  color: white;
  position: relative;

  width: auto;

  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  row-gap: 2rem;
  // column-gap: calc(min(7vh, 7vw));

  z-index: 5;

  // &:hover {
  // }
}

.landing__center-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  column-gap: calc(min(7vh, 7vw));

  & .name {
    display: grid;
    // grid-auto-columns: min(1fr);
    grid-auto-columns: calc(min(10vh, 10vw));
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    & h1 {
      color: #666;
      font-size: calc(min(10vh, 10vw));
      width: 100%;
      aspect-ratio: 1 / 1;

      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      transition: 1s ease-in-out;
    }
  }
}
.landing__center-subtitle {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.7;
  margin-right: calc(min(2.5vh, 2.5vw));
  & h2 {
    font-size: calc(min(1.95vh, 1.95vw));
    font-weight: 300;
    text-transform: lowercase;
    letter-spacing: calc(min(0.3vh, 0.3vw));
  }
}
