.about__container {
  // position: relative;
  // width: 100%;
  // left: calc(5vw + 10rem);
  // left: 0;

  // width: 200vw;
  display: grid;
  width: 100vw;
  height: 100vh;

  // flex-direction: column;
  // background-color: red;
  background: linear-gradient(
    60deg,
    rgba(96, 128, 176, 0.7) 0%,
    rgba(158, 102, 97, 0.7) 40%,
    rgba(35, 49, 73, 0.7) 100%
  );
  // background-color: var(--color-overlay-50);

  overflow: hidden;
  // overflow-y: hidden;
  box-sizing: border-box;

  // transition: $default--transition;

  z-index: 5;
}

.about__conatiner__content {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
}
.about__slides-container {
  display: flex;
  column-gap: 1rem;

  margin-left: calc(5vw + 10rem);

  box-sizing: border-box;
}

.about__slide-column {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;

  // transition: $default--transition;
  // transition: transform 0.3s;
  transition: width 0.3s ease-in-out, filter 0.3s ease-in-out;

  -webkit-filter: sepia(80%) grayscale(50%); /* Safari 6.0 - 9.0 */
  filter: sepia(80%) grayscale(50%);

  &:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
    & img {
      opacity: 1;
    }
  }

  & img {
    opacity: 0.7;
    width: 100%;
    // opacity: 1;
    transition: $default--transition;
  }

  &::-webkit-scrollbar {
    // width: 0px;
    display: none;
  }
}
.about__info {
  font-weight: 300;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-top: 30%;
  padding-bottom: 20%;

  box-sizing: border-box;

  overflow-y: scroll;

  & .text-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0;
    transition: $default--transition;

    & .title {
      font-size: 8rem;
      font-weight: 300;
    }
    & .subtitle {
      font-size: 5rem;
    }
    & .el {
      padding-bottom: 5rem;
    }
    & h2 {
      font-size: 3rem;
      font-weight: 300;
      line-height: 1.2;
    }

    & p {
      // font-size: $font-size--ml;
      line-height: 1.5;
    }
  }
}

.about__container .social-icons {
  width: 3rem;
}

.about__detail-info {
  transition: $default--transition;
}
