.works__container {
  // position: relative;
  // width: 100%;
  // left: calc(5vw + 10rem);
  // left: 0;

  // width: 200vw;
  width: 100vw;

  display: grid;
  grid-auto-rows: 1fr;
  // flex-direction: column;
  // background-color: red;
  overflow: hidden;
  // overflow-y: hidden;
  box-sizing: border-box;

  // transition: $default--transition;
}
.work__container {
  width: 100vw;
  height: 100%;

  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;

  z-index: 5;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.work__content {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;

  box-sizing: border-box;
  // overflow: hidden;
  background-color: var(--color-background);

  transition: transform 0.3s ease-in-out 0s;
}
.work__content--overview {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1vw;
  transition: opacity 0.5s;
}
.work__content.padded {
  padding-top: 6vh;
  padding-bottom: 9vh;
  padding-left: calc(5vw + 10rem);
  padding-right: 6vw;
}

.work__detail-container {
  position: relative;
  // width: calc(90vw - 10rem);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // background-color: red;

  // left: calc(5vw + 10rem);
  left: 0;

  box-sizing: border-box;

  overflow-x: scroll;

  scrollbar-width: none;
  // scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }
}
.detail__slide {
  display: flex;
  height: 85vh;
  min-width: 200px;
  margin-bottom: calc(9vh - 1px);
  transition: $default--transition;
}
.testbutton {
  position: absolute;
  top: -2rem;
  left: 0;
}
.works__picture-container {
  position: relative;
  // width: 50vw;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  // margin-left: calc(5vw + 10rem);

  // background-color: red;
  overflow: hidden;

  & .works_picture {
    height: 85vh;
    width: 65%;
    position: absolute;
    // left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    // aspect-ratio: 2 / 3;
    // background-color: blue;
    background-size: auto 100%;
    background-position: center center;
    box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.4);

    overflow: hidden;

    transition: $default--transition;
    & img {
      height: 125%;
      width: auto;
      transition: height 0.3s ease-in-out;
    }
  }

  & .works_picture.active {
    & img {
      height: 125%;
    }
  }
}
.works__info-container {
  width: 100%;
  height: 95%;
  max-width: 40vw;

  // background-color: red;

  display: grid;
  grid-template-columns: 1fr;
  // grid-template-rows: 1fr 1fr minmax(min-content, max-content);
  grid-template-rows: auto 3fr minmax(min-content, max-content);
  // grid-auto-rows: minmax(min-content, max-content);

  justify-items: flex-end;
  justify-content: flex-end;
  row-gap: 2rem;
  // overflow: visible;

  // padding-right: 5vw;
  box-sizing: border-box;

  z-index: 3;
}

.works__title {
  color: var(--color-primary);
  font-size: calc(min(5vh, 5vw));
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
  // line-height: 1;

  position: relative;
  right: 0vh;

  width: 100%;

  // min-height: 20vh;

  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 8vh;

  // overflow: hidden;

  & .work-number {
    font-weight: 400;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
      display: inline-block;
      line-height: normal;

      // border: 1px solid red;
    }
  }

  & .work-title {
    display: flex;
    justify-content: flex-end;

    & span {
      // position: absolute;
      // line-height: 0;
      border-right: 1px solid var(--color-font);
      padding-right: 3rem;
      letter-spacing: 0.5vw;
    }

    & span:nth-child(2) {
      // position: absolute;
      margin-top: 7vh;
      opacity: 0.7;
    }
  }
}

.works__subtitle {
  // position: absolute;
  // width: 70%;
  // background-color: blue;
  display: flex;
  align-items: center;
  text-align: right;

  box-sizing: border-box;

  h2 {
    font-weight: 400;
  }
}

.works__info__detail {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-rows: auto auto;
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 2rem;

  margin-bottom: 2rem;
  box-sizing: border-box;
  // background-color: green;
}

.works__content-nav {
  position: fixed;
  // bottom: calc(9vh - 16px);
  bottom: 9vh;
  right: 5vw;
  width: calc(90vw - 10rem);
  height: 1px;
  // height: auto;
  z-index: 10;
  transition: transform 0.3s ease-in-out 0s;

  & .bar {
    position: relative;
    transition: width 0.5s ease-in-out, background 1s ease-in-out;
  }
  & .active {
    top: -1px;
    height: 1px;
    // min-width: 5vw;
    background: var(--gradient-primary-90);
  }
  & .disabled {
    height: 1px;
    width: 100%;
    background: var(--color-disabled);
  }

  & .labels {
    position: relative;
    font-size: $font-size--d;
    text-transform: uppercase;
    display: flex;
    margin-top: 1.5rem;

    & .label {
      position: absolute;
      top: -2.2rem;
      // height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      transform: translateX(100vw);
      // transition: $default--transition;

      & .box {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        background-color: var(--color-primary);
        transition: $default--transition;
      }
    }
  }
  & .landing {
    left: 3%;
    transition: transform 0.3s ease-in-out 0.6s, opacity 0.3s 0s;
  }
  & .overview {
    left: 20%;
    transition: transform 0.3s ease-in-out 0.8s, opacity 0.3s 0s;
  }
  & .gallery {
    left: 50%;
    transition: transform 0.3s ease-in-out 1s, opacity 0.3s 0s;
  }
}
.works__content-nav.active-box {
  transition-delay: 0.5s;
}

.works__content__arrow {
  position: fixed;
  top: calc(50vh - 1.5rem);
  right: 3rem;
  width: 3rem;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-self: center;

  cursor: pointer;

  z-index: 10;

  .arrow {
    // background-color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    border-width: 1px 1px 0px 0px;
    border-color: var(--color-font);
    border-style: solid;
    transform: rotate(45deg);

    transition: $default--transition;

    &:hover {
      transform: rotate(405deg);
    }
  }
  .text {
    font-size: $font-size--m;
    text-transform: uppercase;
    margin-left: -1rem;

    position: absolute;
  }
}

.detail__img-container {
  position: relative;
  height: 100%;
  width: auto;

  // display: flex;
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-auto-columns: min-content;
  align-items: flex-end;
  gap: 15rem;

  padding: 0 10vw 0 calc(5vw + 10rem);
  // background-color: yellow;

  box-sizing: border-box;

  transition: transform 0.3s ease-in-out 0s;
  & img {
    // position: absolute;
    height: 100%;
    transition: $default--transition;
    margin-bottom: 9vh;
    // border-radius: 2rem;
    // left: 0;
  }
}

.web-portfolio__desktop {
  width: 200px;
  height: 200px;
}

.slide-img--desktop {
  // position: relative;
  width: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  & svg {
    width: calc(min(25vh, 25vw));
    box-sizing: border-box;
    z-index: 1;
  }

  & .masking {
    height: calc(min(50vh, 50vw));
    aspect-ratio: 7 / 4;
    border-radius: 1vh;

    overflow: hidden;
    border: calc(min(1.2vh, 1.5vw)) solid #1a1a1a;

    box-sizing: border-box;
    & img,
    video {
      position: relative;
      width: 100%;
      height: auto;
      box-sizing: border-box;
    }
  }
}
.slide-img--desktop.overview {
  justify-self: center;
  max-width: 40vw;
}

.slide-img--mobile {
  width: auto;
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  // margin-bottom: 10vh;

  box-sizing: border-box;

  & img,
  video {
    // position: absolute;
    height: 55vh;
    width: auto;
    aspect-ratio: 12 / 25;

    display: block;

    margin-bottom: 7vh;

    background-color: #1a1a1a;

    border-radius: 3vh;
    border: calc((1vw + 1vh) / 2.3) solid #1a1a1a;

    // margin-bottom: 0;
  }
}
.slide-img--mobile.overview {
  justify-self: center;
  // align-items: flex-end;
  align-items: center;

  & img,
  video {
    height: 65vh;
    margin-bottom: 0vh;
  }
}

.work__landing {
  width: 100vw;
  max-width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  // justify-content: flex-start;
  overflow: hidden;
}
.work__landing__img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  max-width: 100vw;
  height: 100%;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  filter: blur(0rem);

  overflow: hidden;

  transition: filter 0.5s linear 0s;
}
.work__landing__overlay {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  filter: blur(2rem);
  background-color: var(--color-overlay-30);

  // z-index: 5;
}
:root {
  --aang: 100px;
}
.work__landing__title-box {
  max-width: 90%;
  display: grid;
  grid-auto-columns: 1fr;
  // grid-auto-columns: 1fr;
  // grid-auto-columns: minmax(3vw, auto);
  grid-auto-flow: column;
  justify-items: center;
  // column-gap: 1rem;

  & h1 {
    // font-size: 7vw;
    font-weight: 800;

    position: relative;
    top: 0;
    left: 0;

    // width: 5vw;
    // margin-left: var(--aang);
    // margin: auto;
    // height: 6.5vw;
    aspect-ratio: 1/1;

    display: flex;
    justify-content: center;

    text-transform: uppercase;
    mix-blend-mode: difference;

    opacity: 1;

    // filter: blur(1vw);

    transition: color 0.5s ease-in-out, text-shadow 0.5s ease-in-out;
    // transition: $default--transition;
  }
}
