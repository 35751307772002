@media (max-width: 60em) {
  // base
  body {
    // max-height: 100vh;
    // overflow: hidden;
    // animation: color-change 10s infinite;
  }

  h2 {
    font-size: 1.6rem;
  }
  h3,
  span,
  p {
    font-size: 1.4rem;
  }
  .cursor--follow {
    display: none;
  }
  .a--opacity--m {
    opacity: 0.2;
  }
  .a--width--m {
    max-width: 0px;
    overflow: hidden;
  }
  .detail-item__title {
    margin-bottom: 1.5rem;
  }
  // Header //
  .header__container {
    // width: 100%;
    left: 0;
    grid-template-rows: 1fr;
    justify-items: flex-start;
  }
  .nav__container {
    max-width: 1px;
    padding-left: 0;
    transition: $default--transition;
  }

  .scroll-status--disabled {
    mix-blend-mode: normal;
  }
  .icons__container {
    display: none;
  }
  .nav {
    // display: none;
    // transform: translateX(-100vw);
  }

  .m--nav__toggle {
    font-size: 3rem;
    font-weight: 300;
    position: fixed;
    top: 7vw;
    right: 7vw;

    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10;
  }
  .m--nav__overlay {
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    // background-color: var(--color-overlay);
    background: var(--background-mobile-nav);
    transition: background-color 0.3s ease-in-out;

    & .icons__container {
      right: 7vw;

      width: 2.5rem;

      display: flex;
      justify-self: flex-end;
      padding-right: 7vw;
    }
  }

  // Landing
  .landing__container {
    animation: color-change 10s linear infinite;
  }
  @keyframes color-change {
    0% {
      background-color: palegreen;
    }
    33% {
      background-color: palevioletred;
    }
    66% {
      background-color: skyblue;
    }
    100% {
      background-color: palegreen;
    }
  }
  .landing-art__container {
    display: none;
  }
  .landing__footer {
    width: 84vw;
    right: 0;
    left: calc(7vw + 0.5rem);
    bottom: 5vh;
    & .round span {
      min-width: 1rem;
      min-height: 1rem;
    }
  }
  //Work Nav//
  .works__content-nav {
    width: calc(88vw - 1rem);
    right: 0;
    left: calc(5vw + 1rem);
    bottom: 4.5rem;
    & .landing {
      left: 0%;
      transition: transform 0.3s ease-in-out 0.6s, opacity 0.3s 0s;
    }
    & .overview {
      left: 33%;
      transition: transform 0.3s ease-in-out 0.8s, opacity 0.3s 0s;
    }
    & .gallery {
      left: 66%;
      transition: transform 0.3s ease-in-out 1s, opacity 0.3s 0s;
    }
    & span {
      transition: $default--transition;
    }
  }
  .works__content__arrow {
    display: none;
  }
  .work__landing {
    & h1 {
      font-size: 10vw;
    }
  }
  .work__landing__titlebox {
    max-height: 10vw;
  }
  .works__title {
    font-size: 5rem;
    display: flex;
    min-height: calc(8rem);
    justify-content: center;
    align-items: flex-end;
    padding-top: 0;
    // padding-top: calc(100% - 4rem);
    // align-items: center;
    & .work-title {
      & span {
        font-size: 5rem;
        border-right: none;
        padding-right: 0;
      }
      & span:nth-child(2) {
        position: relative;
        margin-top: 0;
        top: 3rem;
        opacity: 0.7;
      }
    }
    & .work-number {
      display: none;
    }
  }
///dasd
  .work__content--overview {
    max-height: 100vh;
    grid-template-columns: 1fr;
    // grid-template-rows: minmax(auto, calc(45vh - 8rem)) auto;
    grid-template-rows: 45vh auto;
    align-items: center;
    gap: 8vh;

    padding: 2rem 2rem 25vh 2rem;
    box-sizing: border-box;

    overflow-y: scroll;
  }

  .slide-img--mobile.overview {
    // max-height: 30vh;
    height: 100%;
    margin: 0;
    padding-top: 5vh;
    & img,
    video {
      height: 100%;
    }
  }
  .work__content.padded {
    padding: 2rem 2rem 0rem 2rem;
    // padding: 0;
    // padding-bottom: 0rem;
    box-sizing: border-box;
  }
  .work__content.padded.overview {
    padding: 0rem;
    // padding: 0;
    // padding-bottom: 0rem;
    box-sizing: border-box;
  }
  .works__info-container {
    height: 100%;
    max-width: none;
    grid-template-rows: 1fr auto minmax(min-content, max-content);
    row-gap: 3vh;
    & .works__info__detail {
      margin-bottom: 0rem;
      margin-top: 5vh;
      row-gap: 2vh;

      & .detail-item {
        &:last-child {
          // background-color: red;
          padding-bottom: 0;
        }
      }
    }

    & .works__subtitle {
      font-size: 1.2rem;
    }
  }
  .works__picture-container {
    height: 100%;
    & .works_picture {
      width: 70%;
      height: 100% !important;
      box-sizing: border-box;

      aspect-ratio: auto;

      &:nth-child(1) {
        left: 30% !important;
      }

      &:nth-child(2) {
        left: 15% !important;
      }

      &:nth-child(3) {
        left: 0 !important;
      }
    }
  }
  .slide-img--desktop {
    position: relative;
    width: 100%;
    height: auto;
    // background-color: red;
    align-items: center;

    & svg {
      // position: relative;
      width: 30%;
      height: auto;
    }
    & .masking {
      // position: absolute;
      top: 0vh;
      width: 100%;
      height: auto;

      & img,
      video {
        top: 0vh;
        left: 0vh;
        width: 100%;
      }
    }
  }
  .slide-img--desktop.overview {
    max-height: 70vh;
    aspect-ratio: 1 / 1;
    width: 100%;
    max-width: none;
    align-self: flex-end;
  }

  .slide-img--mobile {
    height: auto;
    margin-bottom: 0;
    & svg {
      height: 45vh;
    }
    & img,
    video {
      max-height: 45vh;
      // left: 0.7vh;
      margin: 0;
    }
  }
  .work__detail-container {
    height: 100%;
    // padding: 5rem 2rem;
  }
  .detail__img-container {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 100vh;

    gap: 5rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10vh 2rem;

    box-sizing: border-box;

    overflow-y: scroll;
  }
  .detail__slide {
    height: 100%;
    width: 100%;
    margin: 0;

    justify-content: center;
    &:last-child {
      padding-bottom: 10vh;
    }
  }
  .work__landing__title-box {
    // grid-auto-columns: minmax(4.5vw, auto);
    & h1 {
      // aspect-ratio: auto;
      height: auto;
    }
  }
  .work__landing__img {
    background-size: auto 110%;
  }
  // About

  .about__conatiner__content {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    overflow-y: scroll;
  }
  .about__slides-container {
    position: fixed;
    margin: 0;
    max-height: 100vh;
    z-index: 4;
  }
  .about__info {
    padding: 0;
    overflow: visible;
    padding-bottom: 100vh;
    z-index: 5;

    & .text-wrapper {
      width: 100%;
      padding: 15vh 3rem 10vh 5rem;

      // background-color: var(--color-overlay);
      background: linear-gradient(
        60deg,
        rgba(96, 128, 176, 0.9) 0%,
        rgba(158, 102, 97, 0.9) 30%,
        rgba(35, 49, 73, 0.9) 100%
      );
      box-sizing: border-box;
      & h2 {
        font-size: 2rem;
      }
      & .title {
        margin-bottom: 2rem;
      }

      & .el {
        padding-bottom: 5rem;
      }
    }
  }
  .about__slide-column {
    overflow: hidden;
    opacity: 0.8 !important;

    -webkit-filter: sepia(0.3) grayscale(0.5); /* Safari 6.0 - 9.0 */
    filter: sepia(0.3) grayscale(0.5);

    &:nth-child(3) {
      display: none;
    }
  }
}
