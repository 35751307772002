@keyframes gradient {
  0% {
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  50% {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  100% {
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
.b--opacity50 {
  opacity: 0.5;
}
.b--opacity100 {
  opacity: 1;
}

.a--opacity {
  opacity: 0.5;
  transition: $default--transition;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.a--transition {
  transition: $default--transition;
}
.b--translateX0 {
  transform: translateX(0);
}
.b--translateX100vw {
  transform: translateX(100vw);
}
